.container {
  text-align: center;
  margin-top: 10vh;
  position: relative;
  z-index: 11;

  .header {
    margin-top: -40px;
    margin-bottom: 20px;
    color: white;
    font-weight: 300;
  }

  .textBox {
    padding: 20px;
  }

  .rating {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 14px 0;

    img {
      width: 30px;
      color: #ffffff;
      margin-right: 9px;
      cursor: pointer;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .createdAt {
    display: flex;
    width: 100%;
    align-items: center;
  }

  .timeStamps {
    text-align: left;

    .arrowIcon {
      margin-top: -10px;
      margin-bottom: -10px;
      margin-left: -1px;
    }
  }
}

.receipt {
  padding: 20px 20px 0;
  display: flex;
  flex-direction: column;
}

.caption {
  color: white;
  margin-bottom: 20px;
}

.text {
  color: white;

  &.golden {
    color: #e7ab76;
  }
}

.textWithIcon {
  display: flex;
  margin-bottom: 20px;
  align-items: center;

  .text {
    text-align: left;
  }

  .icon {
    path {
      fill: #e7ab76;
      color: #e7ab76;
    }
  }

  img {
    fill: #e7ab76;
    height: 25px;
    width: 30px;
    padding-right: 10px;
  }
}

.damageLocations {
  display: flex;
  flex: 1;
  margin-bottom: 20px;

  .damageLocationsText {
    display: flex;
    width: 90%;
    flex-direction: column;
  }


  .icon {
    path {
      fill: #e7ab76;
      color: #e7ab76;
    }
  }

  img {
    fill: #e7ab76;
    height: 25px;
    width: 30px;
    padding-right: 10px;
    align-self: center;
  }
}

.button {
  box-shadow: inset 0 0 0 50px rgba(0, 0, 0, 0),
  0px 2px 4px 0 rgba(0, 0, 0, 0.5);
  background: linear-gradient(197deg, #ffd4a3, #e7ac76);
  font-size: 16px;
  text-align: center;
  color: #324050;
  border-radius: 4px;
  border: none;
  transition: box-shadow 0.1s ease;
  letter-spacing: 1.2px;
  width: 100%;
  padding: 10px 5px;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    cursor: auto;
  }
}

.costSummary {
  width: 85%;
  padding: 0 20px;
  color: white;

  .cost {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
  .totalCostHeader {
    font-weight: bold;
  }
  .totalCostWrapper {
    margin-top: 10px;
    text-align: left;

    .totalCost {
      margin: 10px 0 20px;
      display: flex;
      align-items: center;

      .costCircle {
        border: 1.5px solid #ffd4a3;
        color: #ffd4a3;
        border-radius: 50%;
        font-size: 18px;
        width: 37px;
        height: 37px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
      }
    }
  }

}
