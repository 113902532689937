.container {
  display: flex;
  width: 100%;
  padding: 10px 0;
  justify-content: center;
  align-items: center;
  .map {
    width: 98%;
    border-radius: 5px;
  }
}
