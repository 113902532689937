body,
html {
  max-width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: "Noto Sans", sans-serif;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4f555c;
  font-size: 14px;
}

body {
  position: relative;
}

body::-webkit-scrollbar {
  width: 1.5rem;
  height: 0;
  display: none;
}

body:hover::-webkit-scrollbar {
  display: unset;
}

body::-webkit-scrollbar-track {
   background-color: transparent;
 }

body::-webkit-scrollbar-thumb {
   border-radius: 9999px;
   border: 0.5rem solid rgba(0, 0, 0, 0);
   background-clip: padding-box;
   background-color: #ADADAD;
 }

button {
  font-family: "Noto Sans", sans-serif !important;
  z-index: 1;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Noto Sans", sans-serif;
  text-align: center;
  margin: 0;
  font-weight: 400;
}

h3 {
  font-size: 20px;
}

p,
span {
  font-size: 14px;
}

p {
  margin: 10px 0;
}

label {
  font-size: 14px !important;
}

input,
select,
textarea,
button,
img {
  font-family: "Noto Sans", sans-serif;
}

input {
  margin: 0;
}

.title-div h2 {
  font-family: "Noto Sans";
  letter-spacing: 2px;
  color: #ffd4a3;
  margin: 0px;
  font-size: 8.5vw;
  text-align: center;
}

@media only screen and (max-device-width: 320px) {
  .title-div h2 {
    font-size: 25px;
  }
}

#root,
.site {
  height: 100%;
  width: 100%;
  background-color: #ffff;
  display: flex;
  flex-direction: column;
}

.content {
  height: 100%;
  width: 100%;
  overflow: auto;

}

.footer {
  z-index: 1;
  background-color: #000;
  padding: 10px;
  position: fixed;
  bottom: 0;
  width: 100%;
}

@media only screen and (max-device-width: 320px) {
  .footer {
    padding: 2px;
  }
}

.footer-text {
  font-size: 14px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.footer-link,
.footer-link:active,
.footer-link:focus,
.footer-link:visited,
.footer-link:hover {
  text-decoration: none;
  color: #fff;
}

.button {
  width: 100%;
  height: 44px;
  margin-bottom: 10px;
  cursor: pointer;
  padding: 0;
  pointer-events: all;
}

@media only screen and (max-device-width: 320px) {
  .modal-button {
    font-size: 14px !important;
  }
}

.button-primary,
.button-primary-dark,
.button-primary-disabled {
  box-shadow: inset 0 0 0 50px rgba(0, 0, 0, 0),
    0px 2px 4px 0 rgba(0, 0, 0, 0.5);
  background: linear-gradient(197deg, #ffd4a3, #e7ac76);
  font-size: 16px;
  text-align: center;
  color: #324050;
  border-radius: 4px;
  border: none;
  transition: box-shadow 0.1s ease;
  letter-spacing: 1.2px;
  width: 310px;
}

.button-primary-dark {
  background: #4f555c;
  color: #fff;
}

.button-primary-disabled {
  opacity: 0.5;
}

.button-primary:active {
  box-shadow: inset 0 0 0 50px rgba(180, 120, 60, 1),
    0px 0px 0px 0 rgba(0, 0, 0, 0.5);
}

.button-secondary,
.button-secondary-transparent {
  font-size: 16px;
  text-align: center;
  color: #ffffff;
  border-radius: 6px;
  border: none;
  box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.5);
  background-color: #324050;
  transition: background-color 0.1s ease;
  letter-spacing: 1.2px;
  width: 310px;
}

@media only screen and (max-device-width: 320px) {
  .button-secondary, .button-primary, .button-secondary-transparent {
    width: 270px !important;
  }
}

.button-secondary-transparent {
  background-color: rgba(255, 255, 255, 0.3);
}

.button-secondary:active,
.button-secondary-transparent:active {
  background-color: hsla(0, 0%, 30%, 1);
}

.button-text {
  padding: 0;
  margin: 0;
  display: inline;
  border: none;
  background-color: transparent;
  font-size: inherit !important;
  font-style: inherit !important;
  font-family: inherit !important;
  letter-spacing: inherit !important;
}

.white-paragraph {
  font-size: 13px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  text-align: center;
  color: #ffffff;
  margin: 0px 0px 20px 0px;
}

.long-text {
  display: block;
}
.short-text {
  display: none;
}

@media only screen and (max-device-width: 320px) {
  .short-text {
    display: block;
  }
}

.loading-spinner {
  width: 100%;
  height: 100%;
  top: 0;
  position: fixed;
  display: flex;
  z-index: 20;
  background-color: rgba(50, 64, 80, 0.6);
}

.loader {
  margin: auto auto;
  width: fit-content;
  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loader p {
  color: #FFF;
  text-align: center
}

.loader img {
  width: 150px;
  height: 150px;
}

@keyframes spin {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}

.map-container-element {
  height: 100%;
  width: 100%;
}

.overlay-opacity {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 10;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.75);
}

.overlay-container {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 15;
  top: 0px;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pulse-container {
  display: flex;
  justify-content: center;
  align-content: center;
}

#customer {
  z-index: 3;
  position: absolute;
}

.pulse {
  z-index: 2;
  display: block;
  margin: 0 auto;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #FFD4A3;
  opacity: 0.2;
  box-shadow: 0 0 0 rgba(131, 123, 113, 0.4);
  animation: pulse 3s infinite;
  animation-timing-function: ease-out;
}

@-webkit-keyframes pulse {
  0% {
    transform: scale(0);
    opacity: 0.2;
    box-shadow: 0 0 0 rgba(131, 123, 113, 0.4);
  }

  100% {
    transform: scale(5);
    opacity: 0;
    box-shadow: 0 0 0 rgba(131, 123, 113, 0.4);
  }
}

@keyframes pulse {
  0% {
    transform: scale(0);
    opacity: 0.2;
    box-shadow: 0 0 0 rgba(131, 123, 113, 0.4);
  }

  100% {
    transform: scale(5);
    opacity: 0;
    box-shadow: 0 0 0 rgba(131, 123, 113, 0.4);
  }
}

.overlay-content {
  width: 80vw;
  height: auto;
  border-radius: 6px;
  background-color: #324050;
  display: flex;
  flex-direction: column;
  margin: 4%;
  padding: 5%;
}

.overlay-title {
  font-size: 20px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 1.4px;
  text-align: center;
  color: #ffd4a3;
  margin: 0px;
  margin-bottom: 30px;
}

.overlay-text {
  margin: 0px;
  font-size: 14px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a:link {
  color: #0077be;
}

a:visited {
  color: #ffff;
}

input:focus,
textarea:focus,
button:focus,
img:focus {
  outline: none;
}

/* HELPER CLASSES */

.absolute {
  position: absolute;
}
.relative {
  position: relative;
}
.fixed {
  position: fixed;
}
.flex {
  display: flex;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-alignItemsCenter {
  align-items: center;
}

.flex-justifyContentCenter {
  justify-content: center;
}

.flex-grow {
  flex-grow: 1;
}
.justify-start {
  justify-content: flex-start;
}
.justify-end {
  justify-content: flex-end;
}
.justify-center {
  justify-content: center;
}
.justify-evenly {
  justify-content: space-evenly;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.align-center {
  align-items: center;
}

.wrap {
  flex-wrap: wrap;
}

.z-index1 {
  z-index: 1;
}

.z-index2 {
  z-index: 2;
}

.z-index11 {
  z-index: 11;
}

.full-height-width {
  width: 100%;
  height: 100%;
}

.top-left-0 {
  top: 0px;
  left: 0px;
}

.font-bold {
  font-family: "Noto Sans", serif;
}

.font-italic {
  font-style: italic;
}

.overflowy-scroll {
  overflow-y: scroll;
}

.no-margin {
  margin: 0px;
}

.underline {
  text-decoration: underline;
  cursor: pointer;
}

.marginTop20 {
  margin-top: 20px;
}

.marginTop30 {
  margin-top: 30px;
}

.marginBottom20 {
  margin-bottom: 20px;
}

.marginTop15 {
  margin-top: 15px;
}

.marginTop5 {
  margin-top: 5px;
}

.marginBottom10 {
  margin-bottom: 10px;
}

.marginTop10 {
  margin-top: 10px;
}

.marginBottom5 {
  margin-bottom: 5px;
}

.color-white {
  color: #ffffff;
}

.overflow-hidden {
  overflow-y: hidden;
}

.center-text {
  text-align: center;
}

.hr-gold {
  border: solid 1px #ffd4a3;
  margin: 9px 0;
}

.gold-text {
  color: #e7ab76;
}

.font-weight-600 {
  font-weight: 600;
}

.z-index-1 {
  z-index: 1;
}

.z-index-16 {
  z-index: 16;
}

.full-width {
  width: 100%;
}

.visibility-hidden {
  visibility: hidden;
}

.display-block {
  display: block;
}

.background-none {
  background: none;
}

.letter-spacing-05 {
  letter-spacing: -0.5px;
}

.pointer-events-none {
  pointer-events: none;
}

.uppercase {
  text-transform: uppercase;
}

.blur-background {
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  position: absolute;
  z-index: 1;
}

.css-1dimb5e-singleValue {
  margin-bottom: 10px !important;
}

.css-1jqq78o-placeholder {
  margin-bottom: 12px !important;
}

.bOEaaI {
  width: 295px !important;
  max-width: 100% !important;
  align-self: center;
}

.temporary-error-text {
  margin-top: 0.5rem;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.125rem;
  color: #F5365C;
}

.gm-style iframe + div { border: none !important; }

@media (max-width: 500px) {
  .mobile-view-position {
    justify-content: center;
  }
  .site {
    height: 100%;
    height: 100dvh;
    width: 100vw;
    width: 100dvw;
    background-color: #ffff;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .content {
    height: 100%;
    width: 100%;
  }
}

@media (max-width: 375px) {
  .site {
    width: 100vw;
    width: 100dvw;
    background-color: #ffff;
    display: flex;
    flex-direction: column;
    position: relative;
  }

}

@media (max-height: 760px) {
  .site {
    height: 100%;
  }

  .content {
    width: 100%;
    justify-content: center;
  }

  .background {
    width: 100%;
    overflow: auto;
    position: absolute;
  }

}


