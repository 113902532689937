.confirmOrder-header-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding-bottom: 1rem;
}

.confirmOrder-header-container p {
    margin: 0 0 0 10px;
    font-weight: 700;
    font-size: 18px;
    color: #fff;
}

.confirmOrder-infotext {
    width: 90%;
    line-height: 20px;
}

.confirmOrder-infoBox {
    display: flex;
    flex-direction: column;
    width: 90%;
    padding: 1rem 1rem 0 1rem;
    line-height: 5px;
}

.confirmOrder-infoBox button {
    margin: 10px 0;
}

.infoBox-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.infoBox-header p {
    font-weight: 700;
    font-size: 14px;
}


.infoBox-text {
    color: #ADADAD;
    line-height: 20px;
}

.confirmOrder-buttonContainer {
    margin-top: 10px;
    width: 90%;
}

.confirmOrder-buttonContainer button {
    margin-bottom: 10px;
}

.countdownTimer {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #FFD4A3;
    margin: 10px 0;
}

.countdownTimer svg {
    margin-right: 8px;
    height: 20px;
}

.change-destinationButton {
    margin-top: 10px;
    width: 90%;
}